.container {
  height: 100%;
  width: 100%;

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 28px;
    border-bottom: 1px solid #EAEDF0;
    height: 80px;

    .titleContainer {
      display: flex;
      align-items: center;

      svg {
        cursor: pointer;
      }

      p {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.56px;
        margin-left: 16px;
      }
    }
  }

  .contentContainer {
    height: calc(100vh - 80px);
    overflow-y: auto;
    width: 100%;
    background-color: #F8FAFC;
    padding: 20px 28px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }
  }
}