.container {
  border-radius: 12px;
  border: 1px solid #EAEDF0;
  width: 100%;
  height: max-content;
  background-color: white;
  padding: 16px 24px;
  position: relative;
  overflow: hidden;

  .headContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div:first-of-type {
      display: flex;
      align-items: center;

      p {
        margin-left: 8px;
        font-size: 20px;
        line-height: 140%;
        font-weight: 600;
        letter-spacing: -0.4px;
      }
    }
  }

  .notActivatedHeader {
    div:first-of-type {
      p {
        color: #8F9BA6;
      }

      svg {
        path {
          fill: #8F9BA6;

          &:not(:first-of-type) {
            stroke: #8F9BA6;
          }
        }
      }
    }
  }

  .scheduledVisits {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid #EAEDF0;
  }

  .executionAssignments {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
  }

  .countContainer {
    display: flex;
    align-items: center;
    color: #8F9BA6;
    white-space: pre;

    p {
      color: #2B2F32;
    }
  }

  .notActivatedContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10%;
    position: absolute;
    left: 0;
    top: 44px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(15px);
    z-index: 2;
    border-radius: 12px;

    button:last-of-type {
      margin-left: 12px;
    }
  }
}