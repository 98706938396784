.sidebarContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25%;
  height: 100vh;
  padding: 40px;
  border-right: 1px solid #EAEDF0;

  img {
    width: 138px;
    margin-bottom: 40px;
    cursor: pointer;
  }

  .headMenuContainer {
    &:not(:last-of-type) {
      margin-bottom: 28px;
    }

    .menuTitle {
      color: #8F9BA6;
      letter-spacing: -0.32px;
      text-transform: uppercase;
      margin-bottom: 16px;
    }

    .menuItem {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        display: flex;
        align-items: center;
        min-width: 28px;
        min-height: 28px;
        width: 28px;
        height: 28px;
        margin-right: 12px;
      }

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }

    .activeMenuItem {
      p {
        color: #526BC1;
        font-weight: 600;
      }
    }

    .disabledActiveMenuItem {
      cursor: not-allowed;

      p {
        color: #BDC2C6;
      }

      svg {
        path {
          stroke: #BDC2C6;
        }
      }
    }
  }

  .headDoctorContainer {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    .user {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px;
      border: 1px solid #EAEDF0;
      background-color: #F9FBFD;
      padding: 12px 12px 12px 16px;
      cursor: pointer;
    }

    .language {
      width: 100%;
      max-width: 60px;
      margin-left: 12px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      border: 1px solid #EAEDF0;
      background-color: #F9FBFD;
      padding: 12px;
      cursor: pointer;
    }

    .languageDropDown {
      position: absolute;
      width: 100%;
      max-width: 60px;
      right: 0;
      top: -80px;
      border: 1px solid #526BC1;
      background-color: #F9FBFD;
      border-radius: 12px;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.4px;
        padding: 6px 12px;
        cursor: pointer;

        &:not(:last-of-type) {
          border-bottom: 1px solid #EAEDF0;
        }
      }
    }

    .userButtonDropDown {
      width: 100%;
      position: absolute;
      top: -60px;
      border: 1px solid #526BC1;
      background-color: #F9FBFD;
      border-radius: 12px;
      padding: 12px 12px 12px 16px;

      li {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.4px;

        svg {
          margin-right: 10px;
        }
      }
    }
  }
}