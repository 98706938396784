.wrapperContainer {
  position: relative;
  padding: 20px 40px;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100vh;
    z-index: -1;
  }

  .container {
    display: flex;
    align-items: flex-start;
  }
}