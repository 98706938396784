.callContainer {
  position: relative;
  padding: 12px 24px;
  border-radius: 12px;
  border: 1px solid #EAEDF0;
  background-color: white;
  overflow: hidden;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  p {
    letter-spacing: -0.32px;
  }

  .color {
    content: ' ';
    position: absolute;
    width: 5px;
    height: 100%;
    left: 0;
    top: 0;
  }

  .titleContainer {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      white-space: pre-wrap;
    }

    .toTitle {
      display: flex;
      align-items: center;
      color: #8F9BA6;

      svg {
        margin: 0 7px;
      }
    }
  }

  .timeContainer {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: 4px;

    .time {
      color: #727A81;
      margin-top: 4px;
    }

    .closedType {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #727A81;
      padding: 3px 12px;
      border-radius: 12px;
      background-color: #F2F5F8;
    }

    .addedTime {
      color: #F54646;
      margin-left: 8px;
      margin-right: 12px;
    }
  }
}