@import "../../assets/styles/scss/styles";

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    max-width: 233px;
    margin-top: 49px;
  }

  .title {
    font-size: 16px;
    font-weight: 300;
    color: #727A81;
    text-align: center;
    letter-spacing: 6px;
    margin-top: 24px;

    @media only screen and (max-width: $mobile-width) {
      font-size: 12px;
      margin-top: 3vh;
    }
  }
}