@import "../../assets/styles/scss/styles";

.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #F9FBFD;

  .navBarContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

    .navBar {
      margin: 15vh 0 2vh 0;

      @media only screen and (max-width: $tablet-width) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2vh 0 1vh 0;
      }

      @media only screen and (max-width: $mobile-width) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .buttonTitle {
      position: relative;
      width: 100%;
      max-width: 618px;
      height: 53px;
      border-radius: 32px;
      background-color: #526BC1;
      font-weight: 400;
      cursor: pointer;
      font-size: 18px;
      color: #FFFFFF;

      @media only screen and (max-width: $tablet-width) {
        width: 418px;
      }

      @media only screen and (max-width: $mobile-width) {
        font-size: 14px;
        width: 254px;
      }
    }
  }
}
