.tableContainer {
  padding: 10px;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 80%;

  .tableWrapper {
    border-radius: 12px;
    border: 1px solid #EAEDF0;
    overflow: auto;
    width: 100%;
    height: auto;

    tr:nth-child(even) {
      background-color: #F9FBFD;
    }

    tr:nth-child(odd) {
      background-color: #FFFFFF;
    }

    .table {
      background-color: #F2F5F8;
      line-height: 28px;
      letter-spacing: -0.32px;

      .tableHeadCell {
        background-color: #F2F5F8;
        padding: 6px;
      }

      .headLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .firstLabel {
        color: #2B2F32;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.32px;
      }

      .secondLabel {
        color: rgba(114, 122, 129, 0.60);
        line-height: 24px;
        letter-spacing: -0.32px;
      }

      .firstParagraph {
        color: #2B2F32;
        line-height: 28px;
        letter-spacing: -0.32px;
        padding: 6px;
      }

      .secondParagraph {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #727A81;
        line-height: 24px;
        letter-spacing: -0.32px;
      }

      .batteryLevelContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }

      .error {
        color: #F54646;
      }

      .row {
        border-left: 4px solid transparent;
      }

      .errorRow {
        border-left: 4px solid #F54646;
      }
    }
  }
}