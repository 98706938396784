.callContainer {
  position: relative;
  padding: 12px 24px;
  border-radius: 12px;
  border: 1px solid #EAEDF0;
  background-color: white;
  overflow: hidden;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  p {
    letter-spacing: -0.32px;
  }

  .color {
    content: ' ';
    position: absolute;
    width: 5px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #526BC1;
  }

  .closedTitle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: pre-wrap;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    p {
      overflow: hidden;
    }
  }

  .doctorItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;

      p {
        width: 80px;
        text-align: right;

        &:first-of-type {
          margin-right: 16px;
          color: #8F9BA6;
        }
      }

      .redTime {
        color: #F54646;
      }

      .greenTime {
        color: #2ABE80;
      }
    }

    &:not(:first-of-type) {
      margin-top: 4px;
    }
  }

  .timeContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 4px;

    div {
      display: flex;
      align-items: center;
    }

    & > p {
      display: flex;
      align-items: center;
      color: #727A81;
      cursor: pointer;

      svg {
        transition: all ease-in-out 0.5s;
        transform: rotate(0deg);
        margin-left: 4px;

        path {
          fill: #727A81;
        }
      }

      .rotatedArrow {
        transform: rotate(-180deg);
        transition: all ease-in-out 0.5s;
      }
    }

    .time {
      color: #727A81;
      margin-top: 4px;
    }

    .closedType {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #727A81;
      padding: 3px 12px;
      border-radius: 12px;
      background-color: #F2F5F8;
      margin-left: 12px;
    }

    .addedTime {
      color: #F54646;
      margin-left: 8px;
    }
  }
}