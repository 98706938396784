.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  letter-spacing: -0.32px;
  border-radius: 24px;
  border-width: 1px;
  cursor: pointer;
}

.redButton {
  border: 1px solid #F54646;

  &:hover {
    color: white;
    background-color: #F54646;
  }
}

.blueButton {
  border: 1px solid #526BC1;

  &:hover {
    color: white;
    background-color: #526BC1;
  }
}

.grayButton {
  border: 1px solid #D2D9DE;

  &:hover {
    color: white;
    background-color: #2B2F32;
  }
}

.disabled {
  color: #8F9BA6;
  border-color: #EAEDF0;
  background-color: white;
  cursor: not-allowed;

  &:hover {
    color: #8F9BA6;
    background-color: white;
  }
}