.container {
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #EAEDF0;
  background-color: #F9FBFD;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 4px;

  div {
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
  }

  p:first-of-type {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  p:not(:first-of-type) {
    text-align: right;
  }
}