@import "../../assets/styles/scss/styles";

.barContainer {
    padding: 16px 24px;
    margin-right: 2vh;
    border: 1px solid #EAEDF0;
    border-radius: 12px;
    background-color: #FFFFFF;
    color: #2B2F32;
    font-weight: 600;
    font-size: 20px;
    width: 297px;
    height: 272px;

    @media only screen and (max-width: $tablet-width) {
        margin:0 0 1vh 0;
        height: 242px;
    }

    @media only screen and (max-width: $mobile-width) {
        height: 53px;
        font-size: 16px;
        margin-bottom:1vh;
    }
}

.barContainer:hover {
    background-color: #2F80ED;
    transition: background-color 0.8s, color 0.8s;
    color: #FFFFFF;
}