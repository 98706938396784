@import '../../assets/styles/scss/styles';

.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  background-color: #F9FBFD;
  padding-bottom: 30px;

  .listContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

    .list {
      position: relative;
      width: 387px;
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #FFFFFF;
      border: 1px solid #EAEDF0;
      border-radius: 12px;
      margin-top: 1vh;

      @media only screen and (max-width: $mobile-width) {
        width: 347px;
      }
    }

    .name {
      white-space: normal;
      overflow-wrap: break-word;
      width: 319px;

      @media only screen and (max-width: $mobile-width) {
        width: 279px;
      }
    }


    .title {
      color: #07192A;
      font-size: 20px;
      font-weight: 600;
      margin-top: 3vh;
    }
  }
}