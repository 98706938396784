@font-face {
  font-family: 'Manrope';
  src: url('../../fonts/Manrope-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

/* colors */
$white: #ffffff;
$black: #000000;
$red: #ED3327;
$dark-grey: #4d4d4d;
$light-grey: #b3b3b3;

/* fonts */
$primary-font: 'Manrope';

/* fonts-size */
$title-desktop-font: 48px;
$title-mobile-font: 28px;
$subtitle-desktop-font: 18px;
$subtitle-mobile-font: 16px;

$desktop-big-font: 30px;
$desktop-medium-font: 28px;
$desktop-small-font: 24px;

$mobile-big-font: 18px;
$mobile-small-font: 16px;
