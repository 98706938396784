.tabContainer {
  display: flex;
  align-items: center;

  span {
    font-size: 20px;
    letter-spacing: 1px;
    cursor: pointer;

    &:last-child {
      margin-left: 40px;
    }
  }

  .activeTab {
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 6px;
  }
}