.container {
  height: 100%;
  width: 100%;

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 28px 16px 40px;
    border-bottom: 1px solid #EAEDF0;
    height: 80px;

    p {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.56px;
    }
  }

  .contentContainer {
    height: calc(100vh - 80px);
    width: 100%;
    background-color: #F8FAFC;
    padding: 20px 28px;
    overflow-y: auto;

    .departmentItemContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px;
      border: 1px solid #EAEDF0;
      background-color: white;
      padding: 16px 24px;
      width: 40%;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-bottom: 12px;
      }
    }
  }
}