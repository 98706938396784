.container {
  height: 100%;
  width: 100%;

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 28px;
    border-bottom: 1px solid #EAEDF0;
    height: 80px;

    svg {
      margin-right: 16px;
      cursor: pointer;
    }

    p {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.56px;
    }

    div {
      width: max-content;
      display: flex;
      align-items: center;
    }
  }

  .dateContainer {
    padding: 4px 16px;
    border-radius: 24px;
    border: 1px solid #EAEDF0;
    background-color: white;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: #2B2F32;
    width: max-content;
    margin-top: 12px;
  }

  .contentContainer {
    height: calc(100vh - 80px);
    width: 100%;
    background-color: #F8FAFC;
    padding: 20px 28px;
    overflow-y: auto;

    .headContentContainer {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      .subContainer {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        border-radius: 12px;
        border: 1px solid #EAEDF0;
        height: max-content;
        background-color: white;
        padding: 16px 0 16px 24px;
      }

      .buttonContainer {
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding-bottom: 5px;
        overflow-x: auto;
        padding-right: 24px;

        &::-webkit-scrollbar {
          height: 5px;
          cursor: pointer;
        }
        &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0);
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.15);
          cursor: pointer;
          border-radius: 4px;

          &:hover {
            cursor: pointer;
          }
        }

        .activeButton {
          color: white;
          background-color: #526BC1;
        }

        button {
          white-space: nowrap;

          &:not(:last-of-type) {
            margin-right: 8px;
          }
        }
      }

      .statisticsContainer {
        margin-right: 16px;
        padding: 16px 24px;

        .title {
          width: 100%;
          font-size: 18px;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: -0.36px;
        }

        .callsCountContainer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 0;

          &:not(:last-of-type) {
            border-bottom: 1px solid #EAEDF0;
          }

          .zeroCallsNumber {
            color: #2ABE80;
          }
        }
      }
    }

    .callsContainer {
      margin-top: 24px;

      .headCallsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .callTypesContainer {
          display: flex;
          align-items: center;

          button {
            &:first-of-type {
              margin-right: 8px;
            }
          }

          .activeButton {
            color: white;
            background-color: #526BC1;
          }
        }

        .tabContainer {
          display: flex;
          align-items: center;
          border-radius: 24px;
          border: 1px solid #D2D9DE;
          width: max-content;

          .tabItem {
            padding: 6px 24px;
          }

          .activeTabItem {
            background-color: #FF4B7A;
            outline: none;
            border-radius: 24px;
            color: white;
          }
        }
      }

      .calls {
        margin-top: 24px;

        .titleContainer {
          width: 100%;
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 1fr 1fr 1fr;

          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 16px;

            .dateContainer {
              padding: 4px 16px;
              border-radius: 24px;
              border: 1px solid #EAEDF0;
              background-color: white;
              font-size: 18px;
              font-weight: 600;
              line-height: 26px;
              color: #2B2F32;
              margin-top: unset;
            }
          }

          p {
            color: #8F9BA6;
            text-transform: uppercase;
            text-align: right;

            &:not(:last-of-type) {
              margin-right: 16px;
            }
          }
        }

        .callsContent {
          & > div {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 16px;
            margin-top: 12px;
          }
        }
      }
    }
  }
}