.container {
  .statisticsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;

    .statistics {
      border-radius: 12px;
      border: 1px solid #EAEDF0;
      background-color: white;
      padding: 16px 24px;
      height: max-content;

      & > p {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.36px;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        padding-bottom: 8px;

        &:first-of-type {
          margin-top: 16px;
          border-bottom: 1px solid #EAEDF0;
        }
      }

      .zeroValue {
        color: #2ABE80;
      }

      .positiveValue {
        color: #F54646;
      }
    }
  }

  .zone {
    border-radius: 12px;
    border: 1px solid #EAEDF0;
    background-color: white;
    padding: 16px 24px;

    .titleContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      p {
        color: #8F9BA6;
        text-transform: uppercase;
      }

      p:not(:first-of-type) {
        text-align: right;
      }
    }
  }

  .callsContainer {
    margin-top: 24px;

    .callsHeadContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .optionsButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        border: 1px solid #526BC1;
        padding: 8px 16px;
        border-radius: 24px;
        backdrop-filter: blur(10px);
        color: #526BC1;
        cursor: pointer;
        position: relative;

        svg {
          margin-left: 8px;
        }
      }

      .activeButton {
        svg {
          transform: rotate(180deg);
        }
      }

      .optionsList {
        position: absolute;
        top: 45px;
        background-color: white;
        border: 1px solid #526BC1;
        border-radius: 24px;
        z-index: 5;
        overflow: hidden;

        .optionItem {
          padding: 8px 16px;
          cursor: pointer;

          &:hover {
            background-color: #F2F5F8;
          }

          &:not(:first-of-type) {
            border-top: 1px solid #E3E9ED;
          }
        }

        .activeOptionItem {
          font-weight: 700;
          cursor: not-allowed;
        }
      }

      .tabContainer {
        display: flex;
        align-items: center;
        border-radius: 24px;
        border: 1px solid #D2D9DE;

        .tabItem {
          padding: 6px 24px;
        }

        .activeTabItem {
          background-color: #FF4B7A;
          outline: none;
          border-radius: 24px;
          color: white;
        }
      }
    }

    .callsContent {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
      margin-top: 12px;
    }

    .callsHeaderContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
      margin-top: 12px;
      align-items: center;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      p {
        color: #8F9BA6;
        text-transform: uppercase;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.28px;
        text-align: right;
      }

      .dateContainer {
        padding: 4px 16px;
        border-radius: 24px;
        border: 1px solid #EAEDF0;
        background-color: white;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        color: #2B2F32;
      }
    }
  }
}