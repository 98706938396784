.callsContentContainer {
  width: 100%;
  margin-top: 20px;
  padding-right: 24px;

  .titleContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    p {
      color: #8F9BA6;
      text-transform: uppercase;
    }

    p:not(:first-of-type) {
      text-align: right;
    }
  }
}