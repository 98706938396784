@import 'styles';

* {
  scroll-behavior: smooth;
  box-sizing: border-box;
  color: #2B2F32;
  font-family: $primary-font;
}

html, body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 500;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-weight: 500;
}

a {
  color: #000000;
  text-decoration: none;
}

.authButton {
  position: absolute;
  bottom: -100px;
  left: 0;
  padding: 24px 16px;
  border-radius: 16px;
  width: 100%;
  font-size: 24px;
  color: #07192A;
  background: linear-gradient(180deg, #F5F8F9 0%, #D0DDF7 123.13%);

  @media only screen and (max-width: $tablet-width) {
    font-size: 20px;
  }

  @media only screen and (max-width: $mobile-width) {
    font-size: 16px;
    padding: 18px 10px;
    bottom: -75px;
  }

  &:hover {
    outline: 1px solid #D7EBF5;
  }

  &:active {
    background: linear-gradient(180deg, #DAEBF4 0%, #98B0E0 123.13%);
  }

  &:disabled {
    background: linear-gradient(180deg, rgba(245, 248, 249, 0.40) 0%, rgba(208, 221, 247, 0.40) 123.13%);
    color: #A9C0ED;
  }
}

.container {
  width: 100vw;
  max-width: unset;
}

.contentContainer {
  width: 100%;
  overflow-x: hidden;
}