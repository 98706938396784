@import "../../../assets/styles/scss/styles";

.wrapperContainer {
  border-right: 1px solid #EAEDF0;
  position: relative;
  background: #FFF;
  max-width: 100%;
  width: 390px;
  height: auto;
  flex-shrink: 2;
  display: flex;
  flex-direction: column;
  padding: 0 0 1vw 1vw;

  .container {
    overflow: auto;
    height: 100vh;
    padding-bottom: 80px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .menu {
    background-color: #526BC1;
    padding: 15px;
    margin: 10px;
    border-radius: 12px;
    display: flex;
    cursor: pointer;
  }

  .labelContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }

  .iconContainer {
    margin-right: 10px;
  }

  .plateContainer {
    margin: 8px;
    padding: 16px;
    align-items: stretch;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #EAEDF0;
    background: #F9FBFD;
    display: flex;
    flex-direction: column;

    .selector {
      align-self: stretch;
      flex: 1;
      min-width: 0;
      border-radius: 8px;
      background: #FFF;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .hospitalsContainer {
      overflow-y: auto;
      height: 200px;
      border: 1px solid #EAEDF0;
      background: #FFF;
      border-radius: 8px;
      padding: 2px 10px 0 10px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .title {
      color: #2B2F32;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 140% */
      letter-spacing: -0.4px;
    }

    .selectTitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 162.5%;
      letter-spacing: -0.32px;
    }

    .selectFont {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.32px;
    }

    .filterButton {
      display: flex;
      justify-content: right;
      align-items: center;
      padding: 0 10px;
    }
  }

  .problemPlate {
    border: 1px solid #F54646;
  }
}

.menuContainer {
  border-right: 1px solid #EAEDF0;
  position: relative;
  background: #FFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-right: 7vw;

  .closeMenu {
    margin-bottom: 20px;
  }
}