@import "../../../assets/styles/scss/styles";

.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #F9FBFD;

  .formContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

    .form {
      position: relative;
      text-align: left;
      border: 1px solid #D2D9DE;
      border-radius: 24px;
      background: #FFFFFF;
      padding: 24px;
      margin-top: 15vh;
      min-width: 480px;

      @media only screen and (max-width: $mobile-width) {
        min-width: unset;
      }

      .formHeader {
        font-size: 20px;
        font-weight: 600;

        @media only screen and (max-width: $mobile-width) {
          font-size: 16px;
        }
      }

      .formTitle {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 1vh;

        @media only screen and (max-width: $mobile-width) {
          font-size: 14px;
        }
      }

      .resentButtonTitle {
        position: relative;
        width: 100%;
        height: 52px;
        border-radius: 32px;
        background-color: #526BC1;
        font-weight: 400;
        cursor: pointer;
        font-size: 18px;
        color: #FFFFFF;

        @media only screen and (max-width: $mobile-width) {
          font-size: 14px;
        }
      }

      .errorMessage {
        max-width: 432px;
        font-size: 16px;
        font-weight: 400;
        color: #F54646;
        margin-bottom: 1vh;

        @media only screen and (max-width: $mobile-width) {
          width: 232px;
          font-size: 14px;
        }
      }
    }
  }
}