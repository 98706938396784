.container {
  height: 100%;
  width: 100%;

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 28px;
    border-bottom: 1px solid #EAEDF0;
    height: 80px;

    div {
      width: max-content;
      display: flex;
      align-items: center;

      svg {
        cursor: pointer;
      }
    }

    .title {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.56px;
      margin-left: 16px;
    }
  }

  .contentContainer {
    height: calc(100vh - 80px);
    width: 100%;
    background-color: #F8FAFC;
    padding: 20px 28px;
    overflow-y: auto;

    .headButtonContainer {
      display: flex;
      align-items: center;

      button:not(:last-of-type) {
        margin-right: 8px;
      }

      .activeButton {
        color: white;
        background-color: #526BC1;
      }
    }

    .receptionCallsContentHeader {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 16px;
      margin-top: 12px;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      p {
        color: #8F9BA6;
        text-transform: uppercase;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.28px;
        text-align: right;
      }

      .dateContainer {
        padding: 4px 16px;
        border-radius: 24px;
        border: 1px solid #EAEDF0;
        background-color: white;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        color: #2B2F32;
      }
    }

    .callsContent {
      & > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 16px;
        margin-top: 12px;
      }
    }

    .dateContainer {
      padding: 4px 16px;
      border-radius: 24px;
      border: 1px solid #EAEDF0;
      background-color: white;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
      color: #2B2F32;
      width: max-content;
      margin-top: 12px;
    }

    .betweenDepartmentsCallsContentHeader {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
      margin-top: 12px;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      p {
        color: #8F9BA6;
        text-transform: uppercase;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.28px;
        text-align: right;
      }

      .dateContainer {
        padding: 4px 16px;
        border-radius: 24px;
        border: 1px solid #EAEDF0;
        background-color: white;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        color: #2B2F32;
      }
    }

    .betweenDepartmentsCallsContent {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
      margin-top: 12px;
    }

    .blueCodeCallsContentHeader {
      width: 50%;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr;
      column-gap: 16px;
      margin-top: 12px;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      p {
        color: #8F9BA6;
        text-transform: uppercase;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.28px;
        text-align: right;
      }

      .dateContainer {
        padding: 4px 16px;
        border-radius: 24px;
        border: 1px solid #EAEDF0;
        background-color: white;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        color: #2B2F32;
      }
    }

    .blueCodeCallsContent {
      width: 50%;
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 16px;
      margin-top: 12px;
    }
  }
}