.container {
  position: relative;

  .todayButton {
    display: flex;
    align-items: center;
    margin-left: 16px;
    border: 1px solid #526BC1;
    justify-content: center;
    padding: 8px 16px;
    letter-spacing: -0.32px;
    border-radius: 24px;
    cursor: pointer;

    &:hover {
      color: white;
      background-color: #526BC1;
    }

    svg {
      margin-right: 8px;
    }

    &:hover {
      svg {
        path {
          stroke: white;
        }
      }
    }
  }

  .optionsList {
    position: absolute;
    top: 50px;
    right: 0;
    width: 205px;
    background-color: white;
    border: 1px solid #526BC1;
    border-radius: 24px;
    z-index: 5;
    overflow: hidden;

    .optionItem {
      padding: 8px 16px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;

      &:hover {
        background-color: #F2F5F8;
      }

      &:not(:first-of-type) {
        border-top: 1px solid #E3E9ED;
      }

      //&:last-of-type {
      //  border-top: 1px solid #526BC1;
      //}
    }
  }

  .activeOptionItem {
    font-weight: 700;
    cursor: not-allowed;
  }
}
