@import "../../../assets/styles/scss/styles";

.titleContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .filterTitle {
    color: #2B2F32;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
  }
}


