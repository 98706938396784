.container {
  height: 100%;
  width: 100%;

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 28px 16px 40px;
    border-bottom: 1px solid #EAEDF0;
    height: 80px;

    div {
      width: max-content;
      display: flex;
      align-items: center;
    }

    .title {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.56px;
    }

    .congratulationsContainer {
      padding: 12px 24px;
      border-radius: 12px;
      border: 1px solid #2ABE80;
      background-color: #EAF9F3;
      margin-left: 28px;

      p:first-of-type {
        font-weight: 600;
        margin-right: 4px;
      }

      p:last-of-type {
        letter-spacing: -0.32px;
        color: #727A81;
      }
    }
  }

  .contentContainer {
    height: calc(100vh - 80px);
    width: 100%;
    background-color: #F8FAFC;
    padding: 20px 28px;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    gap: 16px;

    .subContainer {
      width: 100%;

      & > div:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }
}