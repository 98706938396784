@import "../../assets/styles/scss/styles";

.wrapperContainer {
  height: 100%;
  position: relative;
  overflow-y: hidden;
  background-color: #F8FAFC;
  display: flex;

  .container {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    height: 100%;

    .tableContainer {
      display: flex;
      flex-direction: row;
      margin-top: 70px;
      height: 100%;
      position: fixed;
      width: 100%;
      background: var(--Gray-10, #F9FBFD);
    }

    .header {
      display: inline-flex;
      padding: 10px 33px 15px 25px;
      border-bottom: 1px solid #EAEDF0;
      align-items: center;
      justify-content: space-between;
      background: #FFF;
      position: fixed;
      width: 100%;
      height: 70px;

      .items {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .logo {
        margin-right: 220px;
        padding-top: 20px;
        max-width: 150px;
        width: 100%;
        transition: margin-right 1s;

        @media only screen and (max-width: $tablet-width) {
          max-width: 100px;
          margin-right: 30px;
        }

        @media only screen and (max-width: $mobile-width) {
          max-width: 80px;
        }
      }

      .logoTransition {
        margin-right: 30px;
        transition: margin-right 1s;

        @media only screen and (max-width: $tablet-pro-width) {
          max-width: 125px;
        }

        @media only screen and (max-width: $tablet-width) {
          max-width: 100px;
        }

        @media only screen and (max-width: $mobile-width) {
          max-width: 80px;
        }
      }

      .pace {
        border: 1px solid #EAEDF0;
        padding: 10px;
        border-radius: 12px;
        margin-right: 10px;
        cursor: pointer;

        @media only screen and (max-width: $mobile-width) {
          width: 100px;
        }
      }

      .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.56px;

        @media only screen and (max-width: $tablet-pro-width) {
          font-size: 20px;
        }

        @media only screen and (max-width: $tablet-width) {
          font-size: 20px;
        }
      }

      .buttonContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        border: 1px solid #526BC1;
        border-radius: 23px;

        .monitoringButton {
          font-weight: 500;
          font-size: 20px;
          letter-spacing: -0.02em;
          border-radius: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 16px;
          height: max-content;
          transition: background-color 0.5s;

          @media only screen and (max-width: $tablet-pro-width) {
            margin: 0;
          }

          @media only screen and (max-width: $tablet-width) {
            font-size: 18px;
          }
        }

        .selectedButton {
          background-color: #526BC1;
          transition: background-color 1s;
          color: #FFFFFF;
        }
      }

      .button {
        border: 1px solid #2B2F32;
        background-color: #FFF;
        display: flex;
        margin-left: 5px;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        border-radius: 22px;

        @media only screen and (max-width: $tablet-pro-width) {
          padding: 4px 8px;
        }

        @media only screen and (max-width: $tablet-width) {
          padding: 4px 8px;
        }

        @media only screen and (max-width: $mobile-width) {
          padding: 4px 8px;
        }

        .body {
          letter-spacing: -0.32px;

          @media only screen and (max-width: $tablet-width) {
            text-align: center;
          }

          @media only screen and (max-width: $mobile-width) {
            text-align: center;
          }
        }

        .errorBody {
          color: #2B2F32;
        }

        .errorBodyPressed {
          color: #FFFFFF;
        }

        .number {
          display: flex;
          width: 24px;
          height: 24px;
          margin-left: 8px;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
          background-color: #F54646;
          color: #FFFFFF;
          font-size: 14px;
          line-height: 14px;

          @media only screen and (max-width: $mobile-width) {
            display: none;
          }
        }
      }

      .button:hover .body {
        color: #FFFFFF;
        transition: color 0.5s;
      }

      .button:hover{
        background-color: #F54646;
        border-color: #F54646;
        transition: background-color 0.5s, border-color 0.5s;
      }

      .button:hover .errorBody {
        color: #FFFFFF;
        transition: color 0.5s;
      }

      .button:hover .number {
        background-color: #FFFFFF;
        color: #F54646;
        transition: background-color 0.5s, color 0.5s;
      }

      .errorButton {
        border: 1px solid #F54646;
        background-color: #FFF;
      }

      .errorButtonPressed {
        border: 1px solid #F54646;
        background-color: #F54646;
      }
    }
  }
}