@import "../../../assets/styles/scss/styles";

.inputContainer {
  position: relative;

  input {
    bottom: 16px;
    border-radius: 12px;
    border: 1px solid #D2D9DE;
    padding: 12px 16px;
    width: 100%;
    height: 48px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 1vh;

    @media only screen and (max-width: $mobile-width) {
      padding: 6px 8px;
      font-size: 14px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input:focus {
    outline: none;
    border-color: #526BC1;
  }

  .inputError {
    border: 1px solid #F54646;
  }

  .labelError {
    color: #FF3369;
  }
}